import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@mui/material';

const Header = (props) => {
    const { children, ...other } = props;

    return(
        <Typography variant='h5' sx={{textAlign: "center"}}>{children}</Typography>
    );
}

Header.propTypes = {
    children: PropTypes.node.isRequired,
};

  export default Header;