const xmlElementTags = ["headline", "paragraph", "link", "role", "name", "representative", "description", "list", "title"];
const allowEmpty = ["role", "name", "representative", "description"];
const removeEntirelyWhenEmpty = ["headline"];
const allowedToHaveChildren = ["article"];
const allowedToBeChildren = ["paragraph", "link"];

const XMLParser = new (require('react-xml-parser'))();

const convertInputFormToXmlFormat = (submittedData, originalData, contentFile) => {
    var updatedData;
    
    if(originalData[0].name != "root")
    {
      updatedData = [...originalData];
    }
    else
    {
      updatedData = [...originalData[0].children];
    }

    var elementsToBeRemoved = [];

    for(let i = 0; i < updatedData.length; i++)
    {
      for(let tagIndex = 0; tagIndex < xmlElementTags.length; tagIndex++)
      {
        let dataChanged = submittedData.getAll(xmlElementTags[tagIndex] + i);
        let data = updatedData[i].getElementsByTagName(xmlElementTags[tagIndex]);
        let k = 0;

        for(let y = 0; y < data.length; y++)
        {
          if(xmlElementTags[tagIndex] == "link")
          {
            if((dataChanged[k] == "" || dataChanged[k+1] == "") && !allowEmpty.includes(xmlElementTags[tagIndex]))
              elementsToBeRemoved.push([i, y, xmlElementTags[tagIndex]]);
            data[y].children[0].value = dataChanged[k++];
            data[y].children[1].value = dataChanged[k++];
          }
          else if(xmlElementTags[tagIndex] == "list")
          {
            for(let z = 0; z < data[y].children.length; z++)
              data[y].children[z].value = dataChanged[k++];
          }
          else
          {  
            if(dataChanged[y] == "" && !allowEmpty.includes(xmlElementTags[tagIndex]))
              elementsToBeRemoved.push([i, y, xmlElementTags[tagIndex]]);
            data[y].value = dataChanged[y];
          }
        }
      }
    }

    for(let x = elementsToBeRemoved.length - 1; x >= 0 ; x--)
    {
      if(elementsToBeRemoved[x][0] >= updatedData.length)
        continue;
      if(removeEntirelyWhenEmpty.includes(elementsToBeRemoved[x][2]))
        updatedData.splice(elementsToBeRemoved[x][0], 1);
      else
      {
        for(let y = 0; y < allowedToHaveChildren.length; y++)
        {
          let parentDataIndex = updatedData[elementsToBeRemoved[x][0]].children.findIndex((el) => el.name == allowedToHaveChildren[y]); 
          if(parentDataIndex != -1)
          {
            let parentData = updatedData[elementsToBeRemoved[x][0]].children[parentDataIndex]; 
            if(allowedToBeChildren.includes(elementsToBeRemoved[x][2]))
            {
              let childPosition = -1;
              for(let z = 0; z < parentData.children.length; z++)
              {
                if(parentData.children[z].name == elementsToBeRemoved[x][2])
                  childPosition++;
                if(childPosition >= elementsToBeRemoved[x][1])
                {
                  parentData.children.splice(z, 1);
                  break;
                }
              }
            }
          }
        }
      }
    }
    let filename = contentFile.substring(contentFile.lastIndexOf('/') + 1, contentFile.indexOf('.'));
    let root= {
                name: "root",
                attributes: {"filename":filename},
                children: updatedData,
                value: ""
              };
      
    return "<?xml version=\"1.0\"?>" + XMLParser.toString(root);
};

const defaultHeadlineObject = XMLParser.parseFromString(XMLParser.toString({
    name : "headline",
    attributes: {},
    children : [],
    value : ""
}));

const defaultParagraphObject = XMLParser.parseFromString(XMLParser.toString({
  name : "paragraph",
  attributes: {},
  children : [],
  value : ""
}));

const defaultLinkObject = XMLParser.parseFromString(XMLParser.toString({
  name : "link",
  attributes: {},
  children : [{
    name : "displayText",
    attributes: {},
    children : [],
    value : ""
  },{
  name : "address",
  attributes: {},
  children : [],
  value : ""
  }],
  value : ""
}));

const defaultNewsObject = XMLParser.parseFromString(XMLParser.toString({
  name : "news",
  attributes: {},
  children : [
    defaultHeadlineObject, 
    {
      name : "article",
      attributes: {},
      children : [defaultParagraphObject],
      value : ""
    }],
  value : ""
}));

const defaultBoardMemberObject = XMLParser.parseFromString(XMLParser.toString({
  name : "member",
  attributes: {},
  children : [{
      name : "role",
      attributes: {},
      children : [],
      value : ""
    },{
      name : "name",
      attributes: {},
      children : [],
      value : ""
    },{
      name : "representative",
      attributes: {},
      children : [],
      value : ""
    },{
      name : "image",
      attributes: {},
      children : [],
      value : "Comingsoon.jpg"
    },{
      name : "description",
      attributes: {},
      children : [],
      value : ""
    }],
  value : ""
}));

const defaultListItemObject = XMLParser.parseFromString(XMLParser.toString({
  name: "item",
  attributes: {},
  children:[],
  value: ""
}));

const defaultListObject = XMLParser.parseFromString(XMLParser.toString({
  name: "list",
  attributes: {},
  children:[defaultListItemObject],
  value: ""
}));

const defaultContainerObject = XMLParser.parseFromString(XMLParser.toString({
  name: "container",
  attributes: {},
  children:[{
    name : "title",
    attributes: {},
    children : [],
    value : ""
  },{
    name : "paragraph",
    attributes: {},
    children : [],
    value : ""
  }],
  value: ""
}));

const defaultBackgroundImageObject = XMLParser.parseFromString(XMLParser.toString({
  name: "backgroundImage",
  attributes: {},
  children:[],
  value: ""
}));

export {convertInputFormToXmlFormat, defaultNewsObject, 
                                     defaultParagraphObject, 
                                     defaultLinkObject, 
                                     defaultHeadlineObject, 
                                     defaultBoardMemberObject, 
                                     defaultListItemObject, 
                                     defaultListObject,
                                     defaultContainerObject,
                                     defaultBackgroundImageObject};