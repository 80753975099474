import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import {Box, Grid, Stack, Tooltip, IconButton} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveIcon from '@mui/icons-material/Remove';

import {isMobile} from 'react-device-detect';

import 'bootstrap/dist/css/bootstrap.min.css';
import {Carousel, CarouselItem} from 'react-bootstrap';

import { TopLevelControl, ImageControl } from './costumComponents/EditControlButtons';

import Paragraph from './costumComponents/Paragraph';
import { defaultBoardMemberObject } from './hooks/convertInputDataToXmlFormat';

const AboutUsPage = (props) => {
    const { children, value, index, content, modifyContent, filename, reloadMembers, isEditable, ...other } = props;

    const [carouselIndex, setCarouselIndex] = useState(0);
    const [editModeOn, setEditModeOn] = useState(false);
    const [combinedMembers, setCombinedMembers] = useState([]);
    const [keyOffset, setKeyOffset] = useState(1);
    const topLevelControlRef = useRef();

    useEffect(() => {
      setKeyOffset(prev => prev + 1);
    }, [editModeOn, combinedMembers.length]);

    useEffect(() => {
      setCombinedMembers([...content]);
    },[content]);

    const enableEditView = async function() {
      setCombinedMembers([...content]);
      if(editModeOn)
        setCarouselIndex(0);
      setEditModeOn(!editModeOn);
    };

    const removeMember = function(index) {
      combinedMembers.splice(index, 1);
      setCombinedMembers([...combinedMembers]);
    }

    const handleSelect = (selectedIndex) => {
        let items = document.getElementsByClassName("carousel-item");
        for(var i = 0; i < items.length; i++) {
            items[i].style.display = 'none';
        }
        let item = document.getElementById("CarouselItem"+selectedIndex);
        item.style.display = 'flex';
        setCarouselIndex(selectedIndex);
    };
    
    const loadImage = (path) => {
      try
      {
        return (<img src={require("./resources/" + path)} 
                     style={isMobile ? {height:"45vh", width:"100%", objectFit:"scale-down"} 
                                     : {height:"50vh", width:"100%", objectFit:"contain"}}/>);
      }
      catch 
      {
        var source = 'https://kita-kreiselternrat-stade.de/static/media/' + path;
        if(window.location.hostname == "localhost")
          source = 'http://localhost:3000/static/media/' + path;
        return(<img src={source} style={isMobile ? {height:"45vh", width:"100%", objectFit:"scale-down"} 
                                                 : {height:"50vh", width:"100%", objectFit:"contain"}}/>);
      }
    };

    return (
      <div
        role="tabpanel"
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        style={{ display: value !== index ? 'none' : 'flex', width: '100%', alignItems:'center', justifyContent: 'center'}}
        {...other}
      >
        {value === index && (
          <Box component="form" noValidate onSubmit={(event) => {topLevelControlRef.current.handleSubmit(event)}}>         
            {isEditable && (<TopLevelControl editModeOn={editModeOn} 
                                             enableEditView={enableEditView} 
                                             reloadContent={reloadMembers} 
                                             pageContent={combinedMembers} 
                                             setPageContent={modifyContent}
                                             contentFile={filename}
                                             rootTag='member'
                                             ref={topLevelControlRef}
                                             width={isMobile ? '80vw' : '75vw'}/>)}
            <Carousel activeIndex={carouselIndex} 
                      slide={false} 
                      onSelect={handleSelect} 
                      interval={null} 
                      data-bs-theme="dark" 
                      touch={false} 
                      style={isMobile ? {height:"67vh"} : {height:"54vh"}}>
                {combinedMembers.map(function(member, i){
                    return (
                    <CarouselItem key={"CarouselItem"+ i + "_" + keyOffset} 
                                  id={"CarouselItem"+i} 
                                  style={{ display: carouselIndex !== i ? 'none' : 'flex', 
                                           width: '95vw', 
                                           alignItems:'center', 
                                           justifyContent: 'center',
                                           overflow: 'auto'}}>
                      <Grid container spacing={2} sx={isMobile ? {height:"60vh", width: '80vw'} : {height:"55vh", width: '56vw'}}>
                        {
                          isEditable && editModeOn && (
                            <Grid item xs={12} sx={{display: "flex", justifyContent: "flex-end"}}>
                              <Tooltip title="Mitglied entfernen">
                                <IconButton id="removeMemberButton" 
                                            aria-label="removeMember" 
                                            onClick={() => {removeMember(i)}} 
                                            color='secondary'>
                                  <RemoveIcon />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          )
                        }  
                        <Grid item xs={isMobile ? 12 : 4}>
                          {
                            editModeOn && (<ImageControl pageContent={combinedMembers} 
                                                         setPageContent={setCombinedMembers} 
                                                         parentElement='member'
                                                         imageElement='image'
                                                         index={i}
                                                         tooltipText='Bild ändern'/>)
                          }
                          {
                            loadImage(member.getElementsByTagName("image")[0].value) 
                          }   
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 8}>
                          <Stack spacing={2}>
                            <Paragraph variant='h5' 
                                        textAlign='start' 
                                        isEditable={editModeOn} 
                                        id={'role' + i} 
                                        nameOfForm={'role' + i} 
                                        undeletable>
                              {member.getElementsByTagName("role")[0]}
                            </Paragraph>
                            <Paragraph variant='h6' 
                                        textAlign='start'
                                        isEditable={editModeOn} 
                                        id={'name' + i} 
                                        nameOfForm={'name' + i} 
                                        undeletable>
                              {member.getElementsByTagName("name")[0]}
                            </Paragraph>
                            <Paragraph variant='h6' 
                                        textAlign='start' 
                                        isEditable={editModeOn} 
                                        id={'representative' + i} 
                                        nameOfForm={'representative' + i} 
                                        undeletable>
                              {member.getElementsByTagName("representative")[0]}
                            </Paragraph>
                            <Box sx={isMobile ? {} : {height:"35vh", overflowY:"auto"}}>
                              <Paragraph isEditable={editModeOn} 
                                          id={'description' + i} 
                                          nameOfForm={'description' + i} 
                                          undeletable 
                                          multiline={13}>
                                {member.getElementsByTagName("description")[0]}
                              </Paragraph>
                            </Box>
                          </Stack>
                        </Grid>
                      </Grid>
                    </CarouselItem>
                    );
                })}
                {isEditable && editModeOn && (
                    <CarouselItem key={"CarouselItem"+ combinedMembers.length + "_" + keyOffset} 
                                  id={"CarouselItem"+combinedMembers.length} 
                                  style={{ display: carouselIndex !== combinedMembers.length ? 'none' : 'flex', 
                                           width: '95vw', 
                                           height: isMobile ? '60vh' : '55vh',
                                           alignItems:'center', 
                                           justifyContent: 'center',
                                           verticalAlign: 'middle',
                                           overflow: 'auto'}}>
                      <Tooltip title="Neues Mitglied">
                        <IconButton id="addMemberButton" 
                                    aria-label="addMember" 
                                    onClick={() => {setCombinedMembers([...combinedMembers, defaultBoardMemberObject])}} 
                                    color='secondary'
                                    size="large">
                          <AddCircleIcon fontSize="inherit"/>
                        </IconButton>
                      </Tooltip>
                    </CarouselItem>
                )}
            </Carousel>
        </Box>
        )}
      </div>
    );
  }
  
  AboutUsPage.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    content: PropTypes.array.isRequired,
    modifyContent: PropTypes.func.isRequired,
    filename: PropTypes.string.isRequired,
    reloadMembers: PropTypes.func.isRequired,
    isEditable: PropTypes.bool.isRequired,
  };
  
  export default AboutUsPage;