async function storeUpdatedXML(fileName, data) {
    if(window.location.hostname == "localhost")
        return storeUpdatedXMLToLocalFile(fileName, data)
    return storeUpdatedXMLToServer(fileName, data)
};

async function storeUpdatedXMLToServer(fileName, data) {
    let request = {filename: fileName, 
                       data: data, 
                      token: window.sessionStorage.getItem("token"),
                   username: window.sessionStorage.getItem("username")};
    return fetch('https://kita-kreiselternrat-stade.de/handleFileChange.php', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: JSON.stringify(request)
    })
    .then(function(response){ 
        return response.json();   
    }).catch(function(reason){
        console.log(reason);
    });
};

async function storeUpdatedXMLToLocalFile(fileName, data) {
    let request = {filename: fileName, data: data};
    return fetch('http://localhost:8080/saveFile', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(request)
    });
}

export default storeUpdatedXML;