import * as React from 'react';
import PropTypes from 'prop-types';
import {CssBaseline, Box, Typography, Container, Link, Grid, Stack, Button} from '@mui/material';

import { isMobile } from 'react-device-detect';
import { logoutUser } from './hooks/handleUserSession';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary">
      {'Copyright © '}
      <Link color="inherit" href="https://kita-kreiselternrat-stade.de/">
        Kita Elternrat Kreis Stade
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Footer = (props) => {
  const {setValue, setToken, token, username, valueOffset} = props;
  
  function DisplayVisitCounter() {
    const [counter, setCounter] = React.useState(-1);
    
    async function readCounter() {
      var url = "https://kita-kreiselternrat-stade.de/counter.txt";
      if(window.location.hostname == "localhost")
        url = "http://localhost:8080/counter";
      setCounter((await (await fetch(url)).text()).valueOf());
    };

    if(!isMobile && (token == 0 || token == undefined))
    {
      return(<Grid item xs={4}/>);
    }
    else if (token != 0 && token != undefined)
    {
      readCounter();
      return (<Grid item xs={isMobile ? 12 : 4} sx={isMobile ? {} : {textAlign:"center"}}>
                <Typography variant="body2" color="text.secondary">
                  Die Seite wurde {counter} Mal besucht.
                </Typography>
              </Grid>);
    }
  };

  return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <CssBaseline />
        <Box
          component="footer"
          sx={{
            py: 1
          }}
        >
          <Container sx={{width: "95vw"}}>
            <Grid container spacing={1}>
              <Grid item xs={isMobile ? 12 : 4}>
                <Stack spacing={1} direction={"row"}>
                  <Typography variant="body2" color="text.secondary">
                    Über diese Website:
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                  <Link color="inherit" href="#" onClick={() => {setValue(valueOffset + 1)}}>
                    Impressum
                  </Link>
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                  <Link color="inherit" href="#" onClick={() => {setValue(valueOffset + 2)}}>
                    Datenschutzerklärung
                  </Link>
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={isMobile ? 12 : 4} sx={isMobile ? {} : {textAlign:'center'}}>
                  <Typography variant="body2" color="text.secondary">
                    Kontakt: vorstand@kita-kreiselternrat-stade.de
                  </Typography>
              </Grid>
              <Grid item xs={isMobile ? 12 : 4}>
                <Copyright/>
              </Grid>
              <Grid item xs={isMobile ? 12 : 4}>
                  <Typography variant="body2" color="text.secondary">
                  <Link color="inherit" href="#" onClick={(token == 0 || token == undefined) ? () => {setValue(valueOffset + 3)} : () => {logoutUser(username); setToken(0); window.location.reload();}}>
                    {(token == 0 || token == undefined) ? "Login" : "Logout"}
                  </Link>
                  </Typography>
              </Grid>
              <DisplayVisitCounter/>
              <Grid item xs={isMobile ? 12 : 4}>
                <Typography variant="body2" color="text.secondary">
                <Link color="inherit" href="#" onClick={() => {setValue(valueOffset + 4)}}>
                  Geschäftsordnung
                </Link>
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
  );
}

Footer.propTypes = {
  children: PropTypes.node,
  setValue: PropTypes.func.isRequired,
  setToken: PropTypes.func.isRequired,
  token: PropTypes.number.isRequired,
  username: PropTypes.string.isRequired,
  valueOffset: PropTypes.number.isRequired,
};

export default Footer;