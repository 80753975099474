import React from "react";
import {createRoot} from "react-dom/client";
import {Helmet} from "react-helmet";
import MainPage from "./MainPage.js";

const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Helmet>          
        <title>Kita KER Stade</title>
        <link rel="icon" type="image/png" href={require("./resources/Logo5_whiteback.png")} sizes="16x16" style={{backgroundColor:'white'}} />
    </Helmet>
    <MainPage />
  </React.StrictMode>
);
