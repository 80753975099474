import React from 'react';
import PropTypes from 'prop-types';

import { Typography, Box, Stack } from '@mui/material';
import { isMobile } from 'react-device-detect';

const DataProtection = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
          role="tabpanel"
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          style={{ display: value !== index ? 'none' : 'flex', width: '100%', alignItems:'center', justifyContent: 'center'}}
          {...other}
            >
		<Box sx={isMobile ? {height:"67vh", overflowY: 'auto', textAlign:'center'} : {height:"54vh", overflowY: 'auto', textAlign:'center'}}>
		<Stack spacing={4} sx={isMobile ? {width: '90vw'} : {width: '80vw'}}>
			<Stack spacing={2}>
				<Typography variant='h6'>Allgemeine Hinweise</Typography>
				<Typography sx={{textAlign:'justify'}}>Diese Datenschutzerklärung enthält ausführliche Informationen darüber, was mit Ihren persönlichen Daten passiert, wenn Sie unsere Website https://www.kita-kreiseltern-stade.de besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie sich persönlich identifizieren können. Wir halten uns bei der Verarbeitung Ihrer Daten streng an die gesetzlichen Bestimmungen, insbesondere die Datenschutzgrundverordnung („DSGVO“), und legen großen Wert darauf, dass Ihr Besuch auf unserer Website absolut sicher ist.</Typography>
			</Stack>
			<Stack spacing={2}>
				<Typography variant='h6'>Verantwortliche Stelle</Typography>
				<Typography sx={{textAlign:'justify'}}>Datenschutzrechtlich verantwortlich für die Erhebung und Verarbeitung von personenbezogenen Daten auf dieser Website ist, der nach aktueller Geschäftordnung gewählte Vorstand des KER.</Typography>
			</Stack>
			<Stack spacing={2}>
				<Typography variant='h6'>Soziale Medien</Typography>
				<Stack spacing={2}>
					<Typography variant='h6'>Facebook-Plugins (Like &amp; Share-Button)</Typography>
					<Typography sx={{textAlign:'justify'}}>Auf unserer Website sind Plugins des sozialen Netzwerks Facebook, Anbieter Facebook Inc., 1 Hacker Way, Menlo Park, California 94025, USA, integriert („Facebook“). Die Facebook Plugins erkennen Sie an dem Facebook-Logo oder dem „Like-Button“ („Gefällt mir“) auf unserer Website. Eine Übersicht über die Facebook Plugins finden Sie hier: https://developers.facebook.com/docs/plugins/?locale=de_DE.</Typography>
					<Typography sx={{textAlign:'justify'}}>Wir verhindern die unbewusste und ungewollte Erfassung und Übertragung von personenbezogenen Daten an Facebook durch eine sogenannte 2-Klick-Lösung. Um das Plugin zu aktivieren, muss der Nutzer auf die Schaltfläche klicken. Erst durch diesen Klick wird die Erfassung von personenbezogenen Informationen und deren Weitergabe an den Dienstanbieter ausgelöst. Wir weisen darauf hin, dass wir als Betreiber der Website keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch Facebook haben.</Typography>
					<Typography sx={{textAlign:'justify'}}>‌Informationen über den Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch Facebook sowie Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre finden Sie in der Datenschutzerklärung von Facebook unter: https://de-de.facebook.com/privacy/explanation.</Typography>
				</Stack>
			</Stack>
			<Stack spacing={2}>
				<Stack spacing={2}>
					<Typography variant='h6'>Instagram Plugin</Typography>
					<Typography sx={{textAlign:'justify'}}>Auf unseren Seiten sind Funktionen des Dienstes Instagram eingebunden. Diese Funktionen werden durch die Instagram Inc., 1601 Willow Road, Menlo Park, CA 94025, USA, („Instagram“) angeboten. Die Plugins sind mit einem Instagram-Logo beispielsweise in Form einer „Instagram-Kamera“ gekennzeichnet. Eine Übersicht über die Instagram Plugins und deren Aussehen finden Sie hier: http://blog.instagram.com/post/36222022872/introducing-instagram-badges </Typography>
					<Typography sx={{textAlign:'justify'}}>Wir verhindern die unbewusste und ungewollte Erfassung und Übertragung von personenbezogenen Daten an den Dienstanbieter durch eine 2-Klick-Lösung. Um das Social Plugin zu aktivieren, muss der Nutzer auf die Schaltfläche klicken. Erst durch diesen Klick wird die Erfassung von personenbezogenen Informationen und deren Weitergabe an den Dienstanbieter ausgelöst. Wir weisen darauf hin, dass wir als Betreiber der Website keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch Instagram haben.</Typography>
					<Typography sx={{textAlign:'justify'}}>‌Informationen über den Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch Instagram sowie Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre finden Sie in der Datenschutzerklärung von Instagram unter: https://instagram.com/about/legal/privacy/.</Typography>
				</Stack>
			</Stack>
			<Stack spacing={2}>
				<Stack spacing={2}>
					<Typography variant='h6'>X (Twitter) Plugin</Typography>
					<Typography sx={{textAlign:'justify'}}>Unsere Website verwendet Funktionen des Dienstes X (Twitter). Anbieter ist die &nbsp;X Corp., 1355 Market Street, Suite 900, San Francisco, CA 94103 USA („X (Twitter)“).</Typography>
					<Typography sx={{textAlign:'justify'}}>Wir verhindern die unbewusste und ungewollte Erfassung und Übertragung von personenbezogenen Daten an den Dienstanbieter durch eine 2-Klick-Lösung. Um das Social Plugin zu aktivieren, muss der Nutzer auf die Schaltfläche klicken. Erst durch diesen Klick wird die Erfassung von personenbezogenen Informationen und deren Weitergabe an den Dienstanbieter ausgelöst.</Typography>
					<Typography sx={{textAlign:'justify'}}>Bei Nutzung von X (Twitter) und der Funktion „Re-Tweet“ werden von Ihnen besuchte Websites mit Ihrem X (Twitter)-Account verknüpft und in Ihrem X (Twitter)-Feed veröffentlicht. Dabei erfolgt eine Übermittlung von Daten an X (Twitter). Über den Inhalt der übermittelten Daten sowie die Nutzung dieser Daten durch X (Twitter) haben wir keine Kenntnis. Einzelheiten finden Sie in der Datenschutzerklärung von X (Twitter): https://twitter.com/privacy.</Typography>
					<Typography sx={{textAlign:'justify'}}>Sie können Ihre Datenschutzeinstellungen bei X (Twitter) ändern: https://twitter.com/account/settings</Typography>
					<Typography sx={{textAlign:'justify'}}>‌Weitere Informationen über den Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch X (Twitter) sowie Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre finden Sie in der Datenschutzerklärung von X (Twitter) unter: https://twitter.com/privacy.</Typography>
				</Stack>
			</Stack>
			<Stack spacing={2}>
				<Typography variant='h6'>Newsletter</Typography>
				<Typography sx={{textAlign:'justify'}}>Sofern Sie ausdrücklich eingewilligt haben, senden wir an Ihre E-Mail-Adresse regelmäßig unseren Newsletter. Zum Erhalt unseres Newsletters müssen Sie uns Ihre E-Mail-Adresse mitteilen und sie anschließend verifizieren. Ergänzende Daten werden nicht erhoben oder sind freiwillig. Die Verwendung der Daten erfolgt ausschließlich für den Versand des Newsletters.</Typography>
				<Typography sx={{textAlign:'justify'}}>Die bei der Newsletteranmeldung gemachten Daten werden ausschließlich auf Grundlage Ihrer Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO verarbeitet. Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail oder Sie melden sich über den „Austragen“-Link im Newsletter ab. Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.</Typography>
				<Typography sx={{textAlign:'justify'}}>Zur Einrichtung des Abonnements eingegebene Daten werden im Falle der Abmeldung gelöscht. Sollten diese Daten für andere Zwecke und an anderer Stelle an uns übermittelt worden sein, verbleiben diese weiterhin bei uns.</Typography>
			</Stack>
			<Stack spacing={2}>
				<Typography variant='h6'>Kontaktformular</Typography>
				<Typography sx={{textAlign:'justify'}}>Sofern Sie mit uns Kontakt per E-Mail oder über ein Kontaktformular aufnehmen, werden übermittelte Daten einschließlich Ihrer Kontaktdaten gespeichert, um Ihre Anfrage bearbeiten zu können oder um für Anschlussfragen bereitzustehen. Eine Weitergabe dieser Daten findet ohne Ihre Einwilligung nicht statt.</Typography>
				<Typography sx={{textAlign:'justify'}}>Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.</Typography>
				<Typography sx={{textAlign:'justify'}}>Über das Kontaktformular übermittelte Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder keine Notwendigkeit der Datenspeicherung mehr besteht. Zwingende gesetzliche Bestimmungen - insbesondere Aufbewahrungsfristen - bleiben unberührt.</Typography>
			</Stack>
			<Stack spacing={2}>
				<Typography variant='h6'>Datenverwendung und -weitergabe</Typography>
				<Stack spacing={2}>
					<Typography sx={{textAlign:'justify'}}>Die personenbezogenen Daten, die Sie uns z.B. per E-Mail mitteilen (z.B. Ihr Name und Ihre Adresse oder Ihre E-Mail-Adresse), werden wir weder an Dritte verkaufen noch anderweitig vermarkten. Ihre personenbezogenen Daten werden nur zur Korrespondenz mit Ihnen und nur für den Zweck verarbeitet, zu dem Sie uns die Daten zur Verfügung gestellt haben. Zur Abwicklung von Zahlungen geben wir Ihre Zahlungsdaten an das mit der Zahlung beauftragte Kreditinstitut weiter.</Typography>
					<Typography sx={{textAlign:'justify'}}>Die Verwendung von Daten, die bei Ihrem Besuch unserer Website automatisch erhoben werden, erfolgt nur zu den vorstehend genannten Zwecken. Eine anderweitige Verwendung der Daten findet nicht statt.</Typography>
					<Typography sx={{textAlign:'justify'}}>Wir versichern, dass wir Ihre personenbezogenen Daten im Übrigen nicht an Dritte weitergeben, es sei denn, dass wir dazu gesetzlich verpflichtet wären oder Sie uns vorher Ihre Zustimmung gegeben haben.</Typography>
				</Stack>
			</Stack>
			<Stack spacing={2}>
				<Typography variant='h6'>SSL- bzw. TLS-Verschlüsselung</Typography>
				<Typography sx={{textAlign:'justify'}}>Unsere Website benutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von „http://” auf „https://” wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.</Typography>
				<Typography sx={{textAlign:'justify'}}>Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.</Typography>
			</Stack>
			<Stack spacing={2}>
				<Typography variant='h6'>Speicherdauer</Typography>
				<Typography sx={{textAlign:'justify'}}>Personenbezogene Daten, die uns über unsere Website mitgeteilt worden sind, werden nur so lange gespeichert, bis der Zweck erfüllt ist, zu dem sie uns anvertraut wurden. Soweit handels- und steuerrechtliche Aufbewahrungsfristen zu beachten sind, kann die Speicherdauer zu bestimmten Daten bis zu 10 Jahre betragen.</Typography>
			</Stack>
			<Stack spacing={2}>
				<Typography variant='h6'>Betroffenenrechte</Typography>
				<Typography sx={{textAlign:'justify'}}>Hinsichtlich der Sie betreffenden personenbezogenen Daten haben als Betroffener der Datenverarbeitung nach Maßgabe der gesetzlichen Bestimmungen folgende Rechte gegenüber dem Verantwortlichen:</Typography>
			</Stack>
			<Stack spacing={2}>
				<Typography variant='h6'>Widerrufsrecht</Typography>
				<Typography sx={{textAlign:'justify'}}>Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sofern die Verarbeitung Ihrer Daten auf Ihrer Einwilligung beruht, haben Sie das Recht, eine einmal erteilte Einwilligung in die Verarbeitung von Daten gemäß Art. 7 Abs. 3 DSGVO jederzeit mit Wirkung für die Zukunft zu widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt. Speicherung der Daten für Abrechnungs- und buchhalterische Zwecke bleibt von einem Widerruf nicht berührt.</Typography>
			</Stack>
			<Stack spacing={2}>
				<Typography variant='h6'>Auskunftsrecht</Typography>
				<Typography sx={{textAlign:'justify'}}>Sie haben das Recht, gemäß Art. 15 DSGVO von uns eine Bestätigung darüber zu verlangen, ob wir personenbezogene Daten verarbeiten, die Sie betreffen. Liegt eine solche Verarbeitung vor, haben Sie das Recht auf Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten, die Verarbeitungszwecke, die Kategorien der verarbeiteten personenbezogenen Daten, die Empfänger oder Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer bzw. die Kriterien für die Festlegung der Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung, Widerspruch gegen die Verarbeitung, Beschwerde bei einer Aufsichtsbehörde, die Herkunft Ihrer Daten, wenn diese nicht durch uns bei Ihnen erhoben wurden, das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftige Informationen über die involvierte Logik und die Sie betreffende Tragweite und die angestrebten Auswirkungen einer solchen Verarbeitung, sowie Ihr Recht auf Unterrichtung, welche Garantien gemäß Art. 46 DSGVO bei Weiterleitung Ihrer Daten in Drittländer bestehen.</Typography>
			</Stack>
			<Stack spacing={2}>
				<Typography variant='h6'>Recht auf Berichtigung</Typography>
				<Typography sx={{textAlign:'justify'}}>Sie haben das Recht, gemäß Art. 16 DSGVO jederzeit unverzügliche Berichtigung Sie betreffender unrichtiger personenbezogener Daten und/oder die Vervollständigung Ihrer unvollständigen Daten zu verlangen.</Typography>
			</Stack>
			<Stack spacing={2}>
				<Typography variant='h6'>Recht auf Löschung</Typography>
				<Typography sx={{textAlign:'justify'}}>Sie haben das Recht, gemäß Art. 17 DSGVO die Löschung Ihrer personenbezogenen Daten zu verlangen, sofern einer der folgenden Gründe zutrifft:</Typography>
				<Typography sx={{textAlign:'justify'}}>Ihre personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.</Typography>
				<Typography sx={{textAlign:'justify'}}>Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gemäß Art. 6 Abs. 1 lit. a oder Art. 9 Abs. 2 lit. a DSGVO stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung.</Typography>
				<Typography sx={{textAlign:'justify'}}>Sie legen gemäß Art. 21 Abs. 1 DSGVO Widerspruch gegen die Verarbeitung ein und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder Sie legen gemäß Art. 21 Abs. 2 DSGVO Widerspruch gegen die Verarbeitung ein.</Typography>
				<Typography sx={{textAlign:'justify'}}>Die personenbezogenen Daten wurden unrechtmäßig verarbeitet.</Typography>
				<Typography sx={{textAlign:'justify'}}>Die Löschung der personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht des Mitgliedstaates erforderlich, dem wir unterliegen.</Typography>
				<Typography sx={{textAlign:'justify'}}>Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft gemäß Art. 8 Abs. 1 DSGVO erhoben.</Typography>
				<Typography sx={{textAlign:'justify'}}>Dieses Recht besteht jedoch nicht, soweit die Verarbeitung erforderlich ist: </Typography>
				<Stack spacing={2}>
					<Typography sx={{textAlign:'justify'}}>zur Ausübung des Rechts auf freie Meinungsäußerung und Information;</Typography>
					<Typography sx={{textAlign:'justify'}}>zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht der Union oder des Mitgliedstaates, dem wir unterliegen, erfordert, oder zur Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die uns übertragen wurde;</Typography>
					<Typography sx={{textAlign:'justify'}}>aus Gründen des öffentlichen Interesses im Bereich der öffentlichen Gesundheit gemäß Art. 9 Abs. 2 lit. h und i sowie Art. 9 Abs. 3 DSGVO;</Typography>
					<Typography sx={{textAlign:'justify'}}>für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder historische Forschungszwecke oder für statistische Zwecke gemäß Art. 89 Abs. 1 DSGVO, soweit das Betroffenenrecht voraussichtlich die Verwirklichung der Ziele dieser Verarbeitung unmöglich macht oder ernsthaft beeinträchtigt, oder</Typography>
					<Typography sx={{textAlign:'justify'}}>zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</Typography>
				</Stack>
				<Typography sx={{textAlign:'justify'}}>Haben wir Ihre personenbezogenen Daten öffentlich gemacht und sind wir nach Vorstehendem zu deren Löschung verpflichtet, so treffen wir unter Berücksichtigung der verfügbaren Technologie und der Implementierungskosten angemessene Maßnahmen, auch technischer Art, um für die Datenverarbeitung Verantwortliche, die die personenbezogenen Daten verarbeiten, darüber zu informieren, dass Sie als betroffene Person von ihnen die Löschung aller Links zu Ihren personenbezogenen Daten oder von Kopien oder Replikationen dieser personenbezogenen Daten verlangt haben.</Typography>
			</Stack>
			<Stack spacing={2}>
				<Typography variant='h6'>Recht auf Einschränkung der Verarbeitung</Typography>
				<Typography sx={{textAlign:'justify'}}>Sie haben das Recht, gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung (Sperrung) Ihrer personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in folgenden Fällen:</Typography>
				<Stack spacing={2}>
					<Typography sx={{textAlign:'justify'}}>Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. </Typography>
					<Typography sx={{textAlign:'justify'}}>Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah / geschieht, können Sie statt der Löschung die Einschränkung der Datenverarbeitung verlangen. </Typography>
					<Typography sx={{textAlign:'justify'}}>Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. </Typography>
					<Typography sx={{textAlign:'justify'}}>Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</Typography>
				</Stack>
				<Typography sx={{textAlign:'justify'}}>Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats verarbeitet werden.</Typography>
			</Stack>
			<Stack spacing={2}>
				<Typography variant='h6'>Recht auf Unterrichtung</Typography>
				<Typography sx={{textAlign:'justify'}}>Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung gegenüber uns geltend gemacht, sind wir verpflichtet, allen Empfängern, denen Ihre personenbezogenen Daten offengelegt wurden, diese Berichtigung oder Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen, es sei denn, dies erweist sich als unmöglich oder ist mit einem unverhältnismäßigen Aufwand verbunden. Ihnen steht gemäß Art. 19 DSGVO das Recht zu, nach Verlangen über diese Empfänger unterrichtet zu werden.</Typography>
				<Typography sx={{textAlign:'justify'}}>Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung – einschließlich Profiling – beruhenden Entscheidung unterworfen zu werden</Typography>
				<Typography sx={{textAlign:'justify'}}>Sie haben das Recht, gemäß Art. 22 DSGVO nicht einer ausschließlich auf einer automatisierten Verarbeitung – einschließlich Profiling – beruhenden Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche Wirkung entfaltet oder Sie in ähnlicher Weise erheblich beeinträchtigt.</Typography>
				<Typography sx={{textAlign:'justify'}}>Dies gilt nicht, wenn die Entscheidung</Typography>
				<Stack spacing={2}>
					<Typography sx={{textAlign:'justify'}}>für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und uns erforderlich ist,</Typography>
					<Typography sx={{textAlign:'justify'}}>aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der Verantwortliche unterliegt, zulässig ist und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung Ihrer Rechte und Freiheiten sowie Ihrer berechtigten Interessen enthalten oder</Typography>
					<Typography sx={{textAlign:'justify'}}>mit Ihrer ausdrücklichen Einwilligung erfolgt.</Typography>
				</Stack>
				<Typography sx={{textAlign:'justify'}}>Allerdings dürfen die Entscheidungen in den in (a) bis (c) genannten Fällen nicht auf besonderen Kategorien personenbezogener Daten nach Art. 9 Abs. 1 DSGVO beruhen, sofern nicht Art. 9 Abs. 2 lit. a oder lit. g gilt und angemessene Maßnahmen zum Schutz der Rechte und Freiheiten sowie Ihrer berechtigten Interessen getroffen wurden.</Typography>
				<Typography sx={{textAlign:'justify'}}>In den in (a) und (c) genannten Fällen treffen wir angemessene Maßnahmen, um Ihre Rechte und Freiheiten sowie Ihre berechtigten Interessen zu wahren, wozu mindestens das Recht auf Erwirkung des Eingreifens einer Person seitens des Verantwortlichen, auf Darlegung des eigenen Standpunkts und auf Anfechtung der Entscheidung gehört.</Typography>
			</Stack>
			<Stack spacing={2}>
				<Typography variant='h6'>Recht auf Datenübertragbarkeit</Typography>
				<Typography sx={{textAlign:'justify'}}>Sofern die Verarbeitung auf Ihrer Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO oder Art. 9 Abs. 2 lit. a DSGVO oder auf einem Vertrag gemäß Art. 6 Abs. 1 lit. b DSGVO beruht und mithilfe automatisierter Verfahren erfolgt, haben Sie das Recht, gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesebaren Format zu erhalten und einem anderen Verantwortlichen zu übermitteln oder die Übermittlung an einen anderen Verantwortlichen zu verlangen, soweit dies technisch machbar ist.</Typography>
			</Stack>
			<Stack spacing={2}>
				<Typography variant='h6'>Widerspruchsrecht</Typography>
				<Typography sx={{textAlign:'justify'}}>Soweit wir die Verarbeitung Ihrer personenbezogenen Daten auf die Interessenabwägung nach Art. 6 Abs. 1 lit. f DSGVO stützen, haben Sie jederzeit das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, gegen die Verarbeitung Ihrer personenbezogenen Daten Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmung gestütztes Profiling. Die jeweilige Rechtsgrundlage, auf der eine Verarbeitung beruht, entnehmen Sie dieser Datenschutzerklärung. Wenn Sie Widerspruch einlegen, werden wir Ihre betroffenen personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen (Widerspruch nach Art. 21 Abs. 1 DSGVO).</Typography>
				<Typography sx={{textAlign:'justify'}}>Werden Ihre personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, so haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung Sie betreffender personenbezogener Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht. Wenn Sie widersprechen, werden Ihre personenbezogenen Daten anschließend nicht mehr zum Zwecke der Direktwerbung verwendet (Widerspruch nach Art. 21 Abs. 2 DSGVO).</Typography>
				<Typography sx={{textAlign:'justify'}}>Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft – ungeachtet der Richtlinie 2002/58/EG - Ihr Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei denen technische Spezifikationen verwendet werden.</Typography>
			</Stack>
			<Stack spacing={2}>
				<Typography variant='h6'>Beschwerderecht bei der zuständigen Aufsichtsbehörde gemäß Art. 77 DSGVO</Typography>
				<Typography sx={{textAlign:'justify'}}>Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.</Typography>
				<Stack spacing={0}>
					<Typography sx={{textAlign:'justify'}}>Die für uns zuständige Aufsichtsbehörde ist: </Typography>
					<Typography sx={{textAlign:'justify'}}>Die Landesbeauftragte für den Datenschutz Niedersachsen</Typography>
					<Typography sx={{textAlign:'justify'}}> Prinzenstraße 5 </Typography>
					<Typography sx={{textAlign:'justify'}}> 30159 Hannover </Typography>
					<Typography sx={{textAlign:'justify'}}> 05 11/120-45 00 </Typography>
					<Typography sx={{textAlign:'justify'}}> poststelle@lfd.niedersachsen.de </Typography>
					<Typography sx={{textAlign:'justify'}}> https://lfd.niedersachsen.de/ </Typography>
				</Stack>
			</Stack>
			<Stack spacing={2}>
				<Typography variant='h6'>Gültigkeit und Änderung dieser Datenschutzerklärung</Typography>
				<Typography sx={{textAlign:'justify'}}>Diese Datenschutzerklärung gilt ab dem 12. Februar 2024. Wir behalten uns das Recht vor, diese Datenschutzerklärung jederzeit unter Beachtung der geltenden Datenschutzvorschriften zu ändern. Dies kann z.B. zur Einhaltung neuer Gesetzesbestimmungen oder zur Berücksichtigung der Änderungen unserer Website bzw. neuer Dienstleistungen auf unserer Website erforderlich sein. Es gilt die zum Zeitpunkt Ihres Besuchs abrufbare Fassung.</Typography>
				<Typography sx={{textAlign:'justify'}}>Sollte diese Datenschutzerklärung geändert werden, beabsichtigen wir, Änderungen an unserer Datenschutzerklärung auf dieser Seite bekannt zu geben, sodass Sie umfassend darüber informiert sind, welche personenbezogene Daten wir sammeln, wie wir sie verarbeiten und unter welchen Umständen sie weitergegeben werden können.</Typography>
			</Stack>
		</Stack>
		</Box>
		</div>
    );
  }

  DataProtection.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
  };

  export default DataProtection;