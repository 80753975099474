import React from 'react';
import PropTypes from 'prop-types';

import { Typography, Box, Stack } from '@mui/material';
import { isMobile } from 'react-device-detect';

const Impressum = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
          role="tabpanel"
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          style={{ display: value !== index ? 'none' : 'flex', width: '100%', alignItems:'center', justifyContent: 'center' }}
          {...other}
            >
              <Box sx={isMobile ? {height:"67vh", overflowY: 'auto', textAlign:'center'} : {height:"54vh", overflowY: 'auto', textAlign:'center'}}>
              <Stack spacing={1} sx={isMobile ? {width: '90vw'} : {width: '80vw'}}>
                <Typography variant="h6"> Impressumsangaben gemäß § 5 Telemediengesetz und § 55 Rundfunkstaatsvertrag </Typography>
                <Typography sx={{textAlign:'justify'}}> Angaben gemäß § 5 Telemediengesetz (TMG) Die Kreiselternvertretung der Kindertageseinrichtungen und Kindertagespflege des Landkreis Stade ist eine gewählte Interessenvertretung nach § 4 Abs. 2 und 3 des Kindertagesförderungsgesetzes (KiTaG) und wird vertreten durch den Vorstand: </Typography>
                <Typography sx={{textAlign:'justify'}}> Andreas Neumann </Typography>
                <Typography sx={{textAlign:'justify'}}> Katharina Brüggemann </Typography>
                <Typography sx={{textAlign:'justify'}}> Thore Krakowski </Typography>
                <Typography sx={{textAlign:'justify'}}> Julia Dieckmann </Typography>
                <Typography sx={{textAlign:'justify'}}> Lisanne von Allwörden </Typography>
                <Typography sx={{textAlign:'justify'}}> Adina Bauermeister </Typography>
                <Typography sx={{textAlign:'justify'}}> Katharina Fischer </Typography>
                <Typography sx={{textAlign:'justify'}}> Linda Kascha </Typography>
                <Typography sx={{textAlign:'justify'}}> Tanita Müller-Baden </Typography>
                <Typography sx={{textAlign:'justify'}}> Hesna Ott </Typography>
                <Typography sx={{textAlign:'justify'}}> E-Mail: vorstand@kita-kreiselternrat-stade.de</Typography>
                <Typography variant='h6'> Geschäftsstelle der Kreisverwaltung Stade<br/> </Typography>
                <Typography sx={{textAlign:'justify'}}>
                  bei Soziales, Gesundheit, Jugend und Familie<br/>
                  Am Sande 2<br/>
                  21682 Stade<br/>
                  info@landkreis-stade.de<br/>
                  <br/>
                  Dezernentin Susanne Brahmst<br/>
                  Telefon:  04141 122000<br/>
                </Typography>
                <Typography variant='h6'>
                  Rechtliche Hinweise
                </Typography>
                <Typography sx={{textAlign:'justify'}}>
                  Haftung für Inhalte/Links
                  Die Inhalte dieser Seiten wurden mit größter Sorgfalt erstellt.<br/>
                  Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte übernehmen wir jedoch keine Gewähr.<br/>
                  Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben.<br/>
                  Deshalb übernehmen wir für diese fremden Inhalte auch keine Gewähr.<br/>
                  <br/>
                </Typography>
                <Typography variant='h6'>
                  Urheberrecht
                </Typography>
                <Typography sx={{textAlign:'justify'}}>
                  Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht.<br/>
                  Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechts bedürfen der schriftlichen Zustimmung des Erstellers.<br/>
                  Downloads und Kopien dieser Seiten sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.<br/>
                </Typography>
              </Stack>
            </Box>
          </div>
    );
  }

  Impressum.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  export default Impressum;