
async function requestSalt(username) {
    if(window.location.hostname == "localhost")
        return requestSaltFromLocalhost();
    return requestSaltFromServer(username);
};

async function requestSaltFromServer(username) {
    let request = {username: username, action: 'getSalt'};
    return fetch('https://kita-kreiselternrat-stade.de/handleLogin.php', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: JSON.stringify(request)
    })
    .then(function(response){ 
     return response.json();   
    }).catch(function(reason){
        console.log(reason);
    });
};

async function requestSaltFromLocalhost() {
    return {salt: "$2a$10$CwT1cUXWue0T4q9StjUM0u"};
};

async function loginUser(credentials) {
    if(window.location.hostname == "localhost")
        return loginUserIntoLocalhost();
    return loginUserIntoServer(credentials);
};

async function loginUserIntoServer(credentials) {

    return fetch('https://kita-kreiselternrat-stade.de/handleLogin.php', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: JSON.stringify(credentials)
    })
    .then(function(response){ 
     return response.json();   
    }).catch(function(reason){
        console.log(reason);
    });
};

async function loginUserIntoLocalhost() {
    return {token: "1"};
};

async function logoutUser(username) {
    if(window.location.hostname == "localhost")
        return;
    return logoutUserFromServer(username);
}

async function logoutUserFromServer(username) {
    let request = {username: username, action: 'logout'};
    return fetch('https://kita-kreiselternrat-stade.de/handleLogin.php', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: JSON.stringify(request)
    })
};

export {requestSalt, loginUser, logoutUser};