import {React, useState} from 'react';
import PropTypes from 'prop-types';

import {Button, TextField, Box, Typography, Container} from '@mui/material';
import { requestSalt, loginUser } from './hooks/handleUserSession.js';

const SignIn = (props) => {
    const { children, value, setValue, index, setToken, setEditable, setUsername, ...other } = props;
    const bcrypt = require('bcryptjs-react');

    const [invalidLoginError, setInvalidLoginError] = useState(false);
    const [doesNotMatch, setDoesNotMatch] = useState(false);
    const [newPasswordRequired, setNewPasswordRequired] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setInvalidLoginError(false);

        const data = new FormData(event.currentTarget);
        const salt = await requestSalt(data.get("username"));
        const hashedPassword = bcrypt.hashSync(data.get("password"), salt.salt);

        let submitData = {
            username: data.get("username"),
            password: hashedPassword
        };
        
        if(newPasswordRequired)
        {
            if(data.get("newPassword") === data.get("newPasswordCheck"))
            {
                const hashedNewPassword = bcrypt.hashSync(data.get("newPassword"), salt.salt);
                submitData = {
                    username: data.get("username"),
                    action: "storeNewPassword",
                    oldPassword: hashedPassword,
                    newPassword: hashedNewPassword
                  };
            }
            else
            {
                setDoesNotMatch(true);
                return;
            }
        }
        const answer = await loginUser(submitData);
        if(answer.hasOwnProperty("error") && answer.error == "invalidLoginData")
        {
            setInvalidLoginError(true);
        } 
        else if(answer.hasOwnProperty("success") && answer.success == "newPasswordRequired")
        {
            setNewPasswordRequired(true);
        }
        else if(answer.hasOwnProperty("token"))
        {
            setToken(answer.token);
            setEditable(true);
            setUsername(data.get("username"));
            setValue(0);
        }
        else
        {
            setInvalidLoginError(true);
        }
    };
       
    return (
            <Container component="main" maxWidth="xs" sx={{...other}}>
                <Box sx={{display: value !== index ? 'none' : 'flex', flexDirection: 'column', width: '100%', height:"49vh", alignItems: 'center', mt:7}}>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField margin="normal" required fullWidth id="username" label="Name" name="username" autoComplete="username" autoFocus error={invalidLoginError} helperText={invalidLoginError ? "Login Daten falsch": ""}/>
                        <TextField margin="normal" required fullWidth name="password" label="Password" type="password" id="password" autoComplete="current-password" error={invalidLoginError} helperText={invalidLoginError ? "Login Daten falsch": ""}/>
                        {newPasswordRequired ? 
                        <Box sx={{ mt: 1 }}> 
                           <TextField margin="normal" required fullWidth name="newPassword" label="Neues Password" type="password" id="newPassword" autoComplete="current-password" error={doesNotMatch} helperText={doesNotMatch ? "Passwörter stimmen nicht überein": ""}/>
                           <TextField margin="normal" required fullWidth name="newPasswordCheck" label="Neues Password bestätigen" type="password" id="newPasswordCheck" autoComplete="current-password" error={doesNotMatch} helperText={doesNotMatch ? "Passwörter stimmen nicht überein": ""}/>
                        </Box>
                        : <Box/>}
                        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                            Sign In
                        </Button>
                    </Box>
                </Box>
            </Container>
    );
}

SignIn.propTypes = {
    children: PropTypes.node,
    value: PropTypes.number.isRequired,
    setValue: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    setToken: PropTypes.func.isRequired,
    setEditable: PropTypes.func.isRequired,
    setUsername:  PropTypes.func.isRequired,
  };

export default SignIn;