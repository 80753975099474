import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';

import { Masonry } from '@mui/lab';
import { Card, CardContent, Box, Tooltip, IconButton } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { isMobile } from 'react-device-detect';

import Paragraph from './costumComponents/Paragraph'; 
import CostumList from './costumComponents/CostumList';

import { TopLevelControl, ElementLevelControl } from './costumComponents/EditControlButtons';
import { defaultContainerObject } from './hooks/convertInputDataToXmlFormat.js';

const SimpleGridPage = (props) => {
    const { children, value, index, content, modifyContent, filename, reloadContent, isEditable, ...other } = props;

    const [editModeOn, setEditModeOn] = useState(false);
    const [combinedContent, setCombinedContent] = useState([content]);
    const [keyOffset, setKeyOffset] = useState(1);
    const topLevelControlRef = useRef();

    useEffect(() => {
      setKeyOffset(prev => prev + 1);
    }, [editModeOn, combinedContent]);

    useEffect(() => {
      setCombinedContent(content);
    },[content]);
    
    const enableEditView = async function() {
      setCombinedContent(content);
      setEditModeOn(!editModeOn);
    };

    const addCard = async() => {
      var copy = cloneDeep(combinedContent);
      copy[0].children.push(cloneDeep(defaultContainerObject));
      await setCombinedContent(copy);
    };

    const removeCard = async(index) => {
      var copy = cloneDeep(combinedContent);
      copy[0].children.splice(index, 1);
      setCombinedContent(copy);
    };

    const reloadContentOverload = async() => {
      await reloadContent();
      setCombinedContent(content);
    };

    const removeElementFromCard = (cardIndex, eleIndex, eleName) => {
      let copy = cloneDeep(content);
      let backEleIndex = 0;
      let numElements = copy[0].children[cardIndex].getElementsByTagName(eleName).length - eleIndex;
      let elementToSplice = 0;
      for(let k = copy[0].children[cardIndex].children.length - 1; k >= 0; k--)
      {
        if(copy[0].children[cardIndex].children[k].name == eleName)
          backEleIndex++;
        if(backEleIndex == numElements)
        {
          elementToSplice = k;
          break;
        }
      }
      copy[0].children[cardIndex].children.splice(elementToSplice, 1);
      setContent(copy);
    };

    return (
      <div
        role="tabpanel"
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        style={{ display: value !== index ? 'none' : 'flex', width: '100%', alignItems:'center', justifyContent: 'center' }}
        {...other}
      >
        {value === index && combinedContent.length !== 0 && (
          <Box sx={isMobile ? {overflow: 'auto', height:"67vh", width: "95vw"} : {overflow: 'auto', height:"54vh", width: "95vw",textAlign: "center"}}
               component="form" noValidate onSubmit={(event) => {topLevelControlRef.current.handleSubmit(event)}}>
            {isEditable && (<TopLevelControl editModeOn={editModeOn} 
                                             enableEditView={enableEditView} 
                                             reloadContent={reloadContentOverload} 
                                             pageContent={combinedContent} 
                                             setPageContent={modifyContent}
                                             contentFile={filename}
                                             rootTag='root'
                                             ref={topLevelControlRef}/>)}
            <br/>
            {combinedContent[0].getElementsByTagName("header").map(function(child, i)
              {return(
              <Paragraph key={"header" + keyOffset} variant='h5' textAlign="center" isEditable={editModeOn} id={'header' + i} nameOfForm={'header' + i} undeletable>
                {child}
              </Paragraph>);})}
            <br/>
            <Masonry columns={isMobile ? 1 : 3} spacing={2}>
              {combinedContent[0].children.map(function(container, i){
                return(
                  <Box key={"goals-box-"+i} sx={{padding: 0.5}}>
                      {(container.getElementsByTagName("paragraph").length != 0 || 
                        container.getElementsByTagName("list").length != 0 || 
                        container.getElementsByTagName("title").length != 0) && (
                      <Card key={"cards-"+i}>
                        <CardContent>
                          {editModeOn && (<Box sx={{width: "100%", display: "flex", justifyContent: "flex-end"}}>
                            <Tooltip title="Karte löschen">
                              <IconButton aria-label="removeCard" onClick={() => {removeCard(i)}} color='primary'>
                                  <RemoveIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>)}
                          {container.getElementsByTagName("title").map(function(child, y){return(
                            <Paragraph key={"title" + i + "_" + y + "_" + keyOffset} 
                                       topMargin='0px' 
                                       variant='h6' 
                                       textAlign='center'
                                       isEditable={editModeOn} 
                                       id={'title' + i + "_" + y} 
                                       nameOfForm={'title' + i} 
                                       setChildrenValue={(value) => {child.value = value;}}
                                       undeletable>
                              {child}
                            </Paragraph>
                          );})}
                          {editModeOn && (<ElementLevelControl pageContent={combinedContent} 
                                                               setPageContent={setCombinedContent} 
                                                               parentElement='container' 
                                                               index={i}/>
                          )}
                          {container.getElementsByTagName("paragraph").map(function(child, y){return(
                            <Paragraph key={"paragraph_" + i + "_" + y + "_" + keyOffset} 
                                       id={'paragraph_' + i + "_" + y} 
                                       nameOfForm={'paragraph' + i} 
                                       elementWasRemoved={() => {removeElementFromCard(i, y, "paragraph");}}
                                       isEditable={editModeOn} 
                                       setChildrenValue={(value) => {child.value = value;}}
                                       multiline={6}>
                              {child}
                            </Paragraph>
                          );})}
                          {container.getElementsByTagName("list").map(function(child, y){return(
                            <CostumList key={"list" + i + "_" + y + "_" + keyOffset} 
                                        id={'list' + i + "_" + y} 
                                        nameOfForm={'list' + i}
                                        elementWasRemoved={() => {removeElementFromCard(i, y, "list");}}
                                        isEditable={editModeOn}
                                        listObject={child} 
                                        listIcon={"-"}  />
                          );})}
                        </CardContent>
                      </Card>
                      )}
                  </Box>
                );
              })}
              {isEditable && editModeOn && (
                <Card key={"cards-" + combinedContent[0].children.length}>
                  <CardContent>
                      <Tooltip title="Neue Karte">
                        <IconButton id="addCardButton" 
                                    aria-label="addCard" 
                                    onClick={addCard} 
                                    color='secondary'
                                    size="large">
                          <AddCircleIcon fontSize="inherit"/>
                        </IconButton>
                      </Tooltip>
                  </CardContent>
                </Card>
              )}
            </Masonry>
          </Box>
        )}
      </div>
    );
  }
  
  SimpleGridPage.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    content: PropTypes.array.isRequired,
    modifyContent: PropTypes.func.isRequired,
    filename: PropTypes.string.isRequired,
    reloadContent: PropTypes.func.isRequired,
    isEditable: PropTypes.bool.isRequired,
  };

  export default SimpleGridPage;
  