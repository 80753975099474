import React, { useState, useEffect } from 'react';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import NewsPage from "./NewsPage";
import AboutUsPage from "./AboutUsPage";
import SimpleListPage from "./SimpleListPage";
import SimpleGridPage from "./SimpleGridPage";
import SignIn from "./SignIn";
import ContactUs from "./ContactUs";
import Footer from "./Footer";

import logo from "./resources/Logo5.png"
import xmlPages from './resources/pages.xml'
import xmlStatute from './resources/statute.xml'
import Impressum from './impressum';
import DataProtection from './DataProtection';

import { isMobile } from 'react-device-detect';
import useRunOnce from './hooks/useRunOnce';
import { fetchData, fetchJSON, fetchMultipleFiles } from './hooks/handleResources';
import { startSession } from './hooks/startSession';
import cloneDeep from 'lodash/cloneDeep';

const MainPage = () => {
    let assets = 'asset-manifest.json';
    let sessionToken = window.sessionStorage.getItem("token");
    let sessionUsername = window.sessionStorage.getItem("username");
    const [value, setValue] = useState(0);
    const [statute, setStatute] = useState([]);
    const [filePaths, setFilePaths] = useState([]);
    const [pages, setPages] = useState([]);
    const [pageContents, setPageContents] = useState([]);
    const [token, setToken] = useState(sessionToken ? sessionToken : 0);
    const [username, setUsername] = useState(sessionUsername ? sessionUsername : "");
    const [editable, setEditable] = useState(sessionToken ? sessionToken != 0 ? true : false : false);
    const [gotInitialContent, setGotInitialContent] = useState(false);

    useEffect(() => {
      window.sessionStorage.setItem("token", token);
      window.sessionStorage.setItem("username", username);
    }, [token, username]);
    
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    
    async function reloadPage(path, position) {
      var response = [...pageContents];
      response[position] = await fetchData(filePaths.files["static/media/" + path]);
      setPageContents(response);
    }

    async function loadStatute() {
      setStatute((await fetchData(xmlStatute)).getElementsByTagName("root"));
    }
    
    async function loadData() {
      var paths = await fetchJSON(assets);
      setFilePaths(paths);
      var localPages = (await fetchData(xmlPages)).getElementsByTagName("page");
      var filesToFetch = [];

      localPages.map(async function(page) {
        if(page.attributes.path === undefined)
        {
          filesToFetch.push(undefined);
        }
        else
        {
          filesToFetch.push(paths.files["static/media/" + page.attributes.path]);
        }
      });
      var contents = await fetchMultipleFiles(filesToFetch);
      var sorted_contents = cloneDeep(contents);
      var found_indexes = [];
      for(var i = 0; i < contents.length; i++)
      {
        if(contents[i] !== undefined)
        {
          found_indexes.push(localPages.findIndex(p => p.attributes.path == (contents[i].attributes.filename + ".xml")));
          sorted_contents[found_indexes.at(-1)] = contents[i];
        }
      }

      for(var i = 0; i < localPages.length; i++)
      {
        if(localPages[i].attributes.path === undefined)
          sorted_contents[i] = undefined;
      }

      setPageContents(sorted_contents);
      setPages(localPages);
    }

    function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    useRunOnce({fn: 
      async () => {
        await loadData();
        await loadStatute();
        startSession();
        setGotInitialContent(true);
      }
    });
    
    function modifyContent(content, index) {
      var copy = [...pageContents];
      copy[index] = content;
      setPageContents(copy);
    }

    return(
    <div style={{height: '100vh'}}>
        <div style={{ display: 'flex', width: '100%', alignItems:'center', justifyContent: 'center', marginBottom:'10px'}}>
            <img src={logo} alt="logo" style={isMobile ? { alignSelf: 'center', width:"39%", height:"39%"} : { alignSelf: 'center', width:"15%", height:"15%"}}/>
        </div>
                <Box sx={{ width: '100%' }}>
                  <Box>
                      <div style={{ display: 'flex', width: '100%', alignItems:'center', justifyContent: 'center', marginBottom:'10px'}}>
                        <Tabs value={value} variant="scrollable" scrollButtons="auto" onChange={handleChange}>
                          {            
                            pages.map(function(page, i){
                            return(    
                              <Tab label={page.value} {...a11yProps(i)}/>
                            );})
                          }
                        </Tabs>
                      </div>
                  </Box>
                  {            
                    gotInitialContent && pages.map(function(page, i){
                    return(    
                      <div>
                      { page.attributes.type == "NewsPage" &&
                        (<NewsPage value={value} index={i} 
                          content={pageContents[i].getElementsByTagName("news")} 
                          modifyContent={(content) => {modifyContent(content, i);}}
                          filename={filePaths.files["static/media/" + page.attributes.path]} 
                          reloadNews={()=>{reloadPage(page.attributes.path, i)}} 
                          isEditable={editable}/>)
                      }
                      { page.attributes.type == "ContactUs" &&
                        (<ContactUs value={value} setValue={setValue} index={i}/>)
                      }
                      { page.attributes.type == "AboutUsPage" &&
                        (<AboutUsPage value={value} index={i} 
                          content={pageContents[i].getElementsByTagName("member")} 
                          modifyContent={(content) => {modifyContent(content, i);}}
                          filename={filePaths.files["static/media/" + page.attributes.path]} 
                          reloadMembers={()=>{reloadPage(page.attributes.path, i)}} 
                          isEditable={editable}/>)
                      }
                      { page.attributes.type == "SimpleGridPage" &&
                        (<SimpleGridPage value={value} index={i} 
                          content={pageContents[i].getElementsByTagName("root")} 
                          modifyContent={(content) => {modifyContent(content, i);}}
                          filename={filePaths.files["static/media/" + page.attributes.path]} 
                          reloadContent={()=>{reloadPage(page.attributes.path, i)}} 
                          isEditable={editable}/>)
                      }
                      </div>
                    );})
                  }
                  <Impressum value={value} index={pages.length+1}/>
                  <DataProtection value={value} index={pages.length+2}/>
                  <SignIn value={value} setValue={setValue} index={pages.length+3} setToken={setToken} setEditable={setEditable} setUsername={setUsername}/>
                  <SimpleListPage value={value} index={pages.length+4} listContent={statute}/>
                  <Footer setValue={setValue} setToken={setToken} token={token} username={username} valueOffset={pages.length}/>
                </Box>
    </div>
  );
}

export default MainPage;