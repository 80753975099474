import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { Accordion, AccordionDetails, AccordionSummary, Box, Container, CssBaseline, IconButton, Tooltip, Typography } from '@mui/material';

import Paragraph from './costumComponents/Paragraph';
import CostumLink from './costumComponents/CostumLink';
import CostumList from './costumComponents/CostumList';
import { ImageControl, ElementLevelControl, TopLevelControl } from './costumComponents/EditControlButtons';

import { isMobile } from 'react-device-detect';

import {defaultNewsObject} from './hooks/convertInputDataToXmlFormat';

const NewsPage = (props) => {
    const { value, index, content, modifyContent, filename, reloadNews, isEditable, ...other } = props;
    
    const [expanded, setExpanded] = useState(false);
    const [editModeOn, setEditModeOn] = useState(false);
    const [combinedNews, setCombinedNews] = useState([]);
    const [keyOffset, setKeyOffset] = useState(1);
    const topLevelControlRef = useRef();

    useEffect(() => {
      setKeyOffset(prev => prev + 1);
    }, [editModeOn, combinedNews.length]);

    useEffect(() => {
      setCombinedNews([...content]);
    },[content]);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
      };

    const enableEditView = function() {
      setCombinedNews([...content]);
      setExpanded(false);
      setEditModeOn(!editModeOn);
    };

    const addNewsElement = async function() {
      var addedNewsEle = defaultNewsObject;
      setCombinedNews([...combinedNews, cloneDeep(addedNewsEle)]);
    };
    
    const removeNewsElement = function(firstDim, secondDim) {
      let copyNews = [...combinedNews];
      copyNews[firstDim].getElementsByTagName("article")[0].children.splice(secondDim, 1);
      setCombinedNews(copyNews);
    }

    const removeNews = async function(index) {
      let copyNews = [...combinedNews];
      copyNews.splice(index, 1);
      await setCombinedNews(copyNews);
      setExpanded(false);
    };
    
    return (
      <div
        role="tabpanel"
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        style={{ display: value !== index ? 'none' : 'flex', width: '100%', alignItems:'center', justifyContent: 'center' }}
        {...other}
      >
        <Box sx={ isMobile ? {overflow: 'auto', height:"67vh", width: '95vw'} :  {overflow: 'auto', height:"54vh", width: '50vw'}}
             component="form" noValidate onSubmit={(event) => {topLevelControlRef.current.handleSubmit(event)}}>
        {value === index && isEditable == true && (
          <Box>
            <TopLevelControl editModeOn={editModeOn} 
                             enableEditView={enableEditView} 
                             reloadContent={reloadNews} 
                             pageContent={combinedNews} 
                             setPageContent={modifyContent}
                             contentFile={filename}
                             rootTag='news'
                             ref={topLevelControlRef}/>
            <CssBaseline/>
            {
              editModeOn ? 
              <Box sx={{display:"flex", width: "100%", justifyContent:'flex-end'}}>
                <Tooltip title="Neuer Artikel">
                  <IconButton id="addNewsButton" aria-label="addElement" onClick={() => {addNewsElement()}} color='primary'>
                    <AddCircleIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              : 
              <Box/>
            }
          </Box>
        )}
        {value === index && (
          <Box>
          {
            [...combinedNews].reverse().map(function(item, i){
              let backgroundImageUrl = item.getElementsByTagName('backgroundImage');
              let backgroundImage = "";
              if(backgroundImageUrl.length != 0 && backgroundImageUrl[0].value != "")
              {
                try
                {
                  backgroundImage = require('./resources/' + backgroundImageUrl[0].value);
                }
                catch(error)
                {
                  backgroundImage = window.location.hostname == "localhost" ? 'http://localhost:3000/static/media/' + backgroundImageUrl[0].value : 'https://kita-kreiselternrat-stade.de/static/media/' + backgroundImageUrl[0].value;
                }
              }

              return(
                <Accordion id={"accordion" + i} expanded={expanded === 'panel'+ i} onChange={handleChange('panel'+ i)}>
                  <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={"panel"+ i +"bh-content"}
                      id={"panel"+ i +"bh-header"}
                      alignItems="center"
                      justifyContent="center"
                      sx={{backgroundImage: `url(${backgroundImage})`, backgroundRepeat: "no-repeat", backgroundPosition: "right", objectFit:"scale-down"}}>
                        <Paragraph  key={"headline" + keyOffset}
                                    id={"headline" + (combinedNews.length - i - 1)} 
                                    nameOfForm={"headline" + (combinedNews.length - i - 1)}
                                    textAlign="left"
                                    isEditable={editModeOn}
                                    elementWasRemoved={() => {removeNews(cloneDeep(combinedNews.length - i - 1));}}
                                    setChildrenValue={(value) => {item.getElementsByTagName("headline")[0].value = value;}}
                                    sx={isMobile ? { flexShrink: 0, width: '90vw'} : { flexShrink: 0, width: '45vw'}}>
                        {item.getElementsByTagName("headline")[0]}
                        </Paragraph>
                        {editModeOn && (<ImageControl pageContent={combinedNews} 
                                                      setPageContent={setCombinedNews} 
                                                      parentElement='news'
                                                      imageElement='backgroundImage'
                                                      index={combinedNews.length - i - 1}/>)}
                  </AccordionSummary>
                  <AccordionDetails>
                      {editModeOn && (<ElementLevelControl pageContent={combinedNews} 
                                                           setPageContent={setCombinedNews} 
                                                           parentElement='article' 
                                                           index={combinedNews.length - i - 1}/>)}
                      <Typography sx={editModeOn ? { p: 2, border: '1px solid grey' } : {}}>
                      {
                          item.getElementsByTagName("article")[0].children.map(function(child, y){
                              return(
                                  <Container>
                                    {child.name == "paragraph" && 
                                    (<Paragraph key={"paragraph" + keyOffset + "_" + y}
                                                id={"paragraph" + (combinedNews.length - i - 1) + "_" + y} 
                                                nameOfForm={"paragraph" + (combinedNews.length - i - 1)} 
                                                textAlign={isMobile ? "left" : "justify"}
                                                elementWasRemoved={() => {removeNewsElement(cloneDeep(combinedNews.length - i - 1), cloneDeep(y));}}
                                                setChildrenValue={(value) => {child.value = value;}}
                                                multiline={6}
                                                isEditable={editModeOn}>{child}</Paragraph>)}
                                    {child.name == "link" && 
                                    (<CostumLink  key={"link" + keyOffset + "_" + y}
                                                  id={"link" + (combinedNews.length - i - 1) + "_" + y} 
                                                  nameOfForm={"link" + (combinedNews.length - i - 1)}
                                                  elementWasRemoved={() => {removeNewsElement(cloneDeep(combinedNews.length - i - 1), cloneDeep(y));}}
                                                  isEditable={editModeOn}
                                                  address={child.getElementsByTagName("address")[0].value}
                                                  displayText={child.getElementsByTagName("displayText")[0].value}/>)}
                                    {child.name == "list" && 
                                    (<CostumList  key={"list" + keyOffset + "_" + y}
                                                  id={"list" + (combinedNews.length - i - 1) + "_" + y} 
                                                  nameOfForm={"list" + (combinedNews.length - i - 1)}
                                                  elementWasRemoved={() => {removeNewsElement(cloneDeep(combinedNews.length - i - 1), cloneDeep(y));}}
                                                  isEditable={editModeOn}
                                                  listObject={child} 
                                                  listIcon={"-"}/>)}
                                  </Container>
                              );
                          })
                      }
                      </Typography>
                  </AccordionDetails>
                </Accordion>
              );
          })}
          </Box>
        )}
        </Box>
      </div>
    );
  }
  
  NewsPage.propTypes = {
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    content: PropTypes.array.isRequired,
    modifyContent: PropTypes.func.isRequired,
    filename: PropTypes.string.isRequired,
    reloadNews: PropTypes.func.isRequired,
    isEditable: PropTypes.bool.isRequired,
  };

  export default NewsPage;