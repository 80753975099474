
async function startSession() {
    if(window.location.hostname == "localhost")
        return;
    return startSessionOnServer();
};

async function startSessionOnServer() {
    return fetch('https://kita-kreiselternrat-stade.de/startSession.php', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
};

export {startSession};