import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@mui/material';

const Title = (props) => {
    const { children, topMargin,...other } = props;

    return(
        <Typography variant='h6' sx={{textAlign: "center", marginTop: topMargin}}>{children}</Typography>
    );
}

Title.propTypes = {
    children: PropTypes.node.isRequired,
    topMargin: PropTypes.string.isRequired,
};

  export default Title;