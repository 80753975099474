import React, {useState} from 'react';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';

import { TextField, Typography, IconButton, Box, Tooltip } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';

const Paragraph = (props) => {
    const { children, setChildrenValue, id, isEditable, nameOfForm, elementWasRemoved, textAlign, variant, undeletable, multiline, topMargin, ...other } = props;
    const [editedValue,  setEditedValue] = useState();

    var showDeletButton = true;
    if(undeletable)
        showDeletButton = false;

    const alterValue = (event) => {
        let copy = cloneDeep(children);
        copy.value = event.target.value;
        if(setChildrenValue)
            setChildrenValue(event.target.value);
        setEditedValue(copy);
    };
    
    if(isEditable)
    {
        return(
            <Box sx={{width: "100%"}}>
                {showDeletButton && (<Box sx={{display:"flex", width: "100%", justifyContent:'flex-end'}}> 
                    <Tooltip title="Element löschen">
                        <IconButton aria-label="removeParagraph" onClick={() => {elementWasRemoved();}} color='primary'>
                            <RemoveIcon />
                        </IconButton>
                    </Tooltip>
                </Box>)}
                <TextField id={id} 
                           name={nameOfForm} 
                           multiline = {multiline}
                           rows={multiline ? multiline : ""}
                           sx={{textAlign: textAlign ? textAlign : "justify", width: "100%",  marginTop: topMargin}} 
                           variant="standard" 
                           onChange={(event) => {alterValue(event);}}
                           defaultValue={editedValue ? editedValue.value : children.value}/>
            </Box>
        );
    }
    else
        return(
            <Typography variant={variant ? variant : 'body1'} id={id} sx={{textAlign: textAlign ? textAlign : "justify"}}>{children.value}</Typography>
        );
}

Paragraph.propTypes = {
    children: PropTypes.node.isRequired,
    setChildrenValue: PropTypes.func,
    id: PropTypes.string,
    isEditable: PropTypes.bool,
    nameOfForm: PropTypes.string,
    elementWasRemoved: PropTypes.func,
    textAlign: PropTypes.string,
    variant: PropTypes.string,
    undeletable: PropTypes.bool,
    multiline: PropTypes.number,
    topMargin: PropTypes.string,
};

  export default Paragraph;