import {React, useState} from 'react';
import PropTypes from 'prop-types';

import {Button, TextField, Box, Typography, Container} from '@mui/material';
import { isMobile } from 'react-device-detect';

async function sendMail(messageJson) {

    return fetch('https://kita-kreiselternrat-stade.de/handleMail.php', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: JSON.stringify(messageJson)
    })
    .then(function(response){ 
        return response.text();   
       });
}

const ContectUs = (props) => {
    const { children, value, setValue, index, ...other } = props;
    const [emptyMessage, setEmptyMessage] = useState(false);
    const [messageTooLong, setMessageTooLong] = useState(false);
    const [canNotSend, setCanNotSend] = useState(true);
    const [messageLength, setMessageLength] = useState(0);
    const maxMessageLength = 350;

    function resetForm() {
        document.getElementById("contact").value = "";
        document.getElementById("message").value = "";
    }

    function checkIfMessageIsTooLong(event) {
        setMessageLength(event.target.value.length);
        if(event.target.value.length > maxMessageLength)
        {
            setMessageTooLong(true);
            setCanNotSend(true);
        }
        else if(event.target.value.trim() == "")
        {
            setEmptyMessage(true);
            setCanNotSend(true);
        }
        else
        {
            setMessageTooLong(false);
            setEmptyMessage(false);
            setCanNotSend(false);
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        setEmptyMessage(false);
        setMessageTooLong(false);

        const data = new FormData(event.currentTarget);
        if(data.get("message").trim() == "")
        {
            setEmptyMessage(true);
            return;
        }
        const result = await sendMail({
            contactData: data.get("contact"),
            message: data.get("message")
          });
          
        resetForm();
        setValue(0);
    };
       
    return (
            <Container component="main" maxWidth="xs" sx={{...other}}>
                <Box sx={{display: value !== index ? 'none' : 'flex', flexDirection: 'column', width: '100%', height:"49vh", alignItems: 'center', mt:7}}>
                    <Typography component="h1" variant="h5">
                        Kontakt
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField margin="normal" fullWidth id="contact" label="Wie können wir dir Antworten?" name="contact" autoFocus/>
                        <TextField 
                            sx={isMobile ? {width: "100%", height: "22vh"} : {width: "100%", height: "20vh"}}
                            onChange={checkIfMessageIsTooLong}
                            margin="normal" 
                            required
                            multiline
                            rows="7"
                            name="message" 
                            label="Was möchtest du uns mitteilen?" 
                            id="message" 
                            error={emptyMessage|messageTooLong} 
                            helperText={emptyMessage ? "Die Nachricht darf nicht leer sein"
                                                     : messageTooLong ? "Die Nachricht ist zu lang " + messageLength + "/" + maxMessageLength 
                                                                      : messageLength + "/" + maxMessageLength}/>
                        <Button disabled={canNotSend} type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                            Nachricht senden
                        </Button>
                    </Box>
                </Box>
            </Container>
    );
}

ContectUs.propTypes = {
    children: PropTypes.node,
    value: PropTypes.number.isRequired,
    setValue: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
  };

export default ContectUs;