import React, {useState} from 'react';
import PropTypes from 'prop-types';

import { Box, IconButton, Link, TextField, Tooltip, Typography } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';

const CostumLink = (props) => {
    const { children, displayText, address, isEditable, nameOfForm, elementWasRemoved, ...other } = props;
    const [editable, setEditable] = useState(false);

    if(isEditable != editable)
        setEditable(isEditable);

    if(isEditable)
    {
        return(
            <Box sx={{width: "100%", p: 2, border: '1px solid grey' }}>
                <Box sx={{display:"flex", width: "100%", justifyContent:'flex-end'}}> 
                    <Tooltip title="Element löschen">
                        <IconButton aria-label="removeParagraph" onClick={() => {elementWasRemoved();}} color='primary'>
                            <RemoveIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Typography variant='caption'>Felder für den Link</Typography>
                <TextField sx={{textAlign: "justify", width: "100%"}} name={nameOfForm} helperText={"Angezeigter Text"} variant="standard" defaultValue={displayText}/>
                <TextField sx={{textAlign: "justify", width: "100%"}} name={nameOfForm} helperText={"Ziel Adresse"} variant="standard" defaultValue={address}/>
            </Box>
        );
    }
    return(
        <Link color="primary" rel="noreferrer" target="_blank" href={address}>{displayText}</Link>
    );
}

CostumLink.propTypes = {
    children: PropTypes.node,
    displayText: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    isEditable: PropTypes.bool,
    nameOfForm: PropTypes.string,
    elementWasRemoved: PropTypes.func,
};

export default CostumLink;