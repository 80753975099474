import React from 'react';
import PropTypes from 'prop-types';

import {Box, Stack} from '@mui/material';

import { isMobile } from 'react-device-detect';
import Header from './costumComponents/Header';
import Title from './costumComponents/Title';
import Paragraph from './costumComponents/Paragraph';
import CostumList from './costumComponents/CostumList';

const SimpleListPage = (props) => {
    const { children, value, index, listContent, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        style={{ display: value !== index ? 'none' : 'flex', width: '100%', alignItems:'center', justifyContent: 'center' }}
        {...other}
      >
        {value === index && (
          <Stack spacing={0} sx={isMobile ? {overflow: 'auto', height:"67vh", width: "95vw"} : {overflow: 'auto', height:"54vh", width: "95vw",textAlign: "center"}}>
            {listContent[0].children.map(function(child, i){
              return(
                <Box>
                  {child.name == "header" && (<Header>{child.value}</Header>)}
                  {child.name == "title" && (<Title topMargin='20px'>{child.value}</Title>)}
                  {child.name == "paragraph" && (<Paragraph>{child}</Paragraph>)}
                  {child.name == "list" && (<CostumList listObject={child} listIcon={"countWithBrace"}></CostumList>)}
                </Box>
              );
            })}
          </Stack>
        )}
      </div>
    );
  }
  
  SimpleListPage.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    listContent: PropTypes.array,
  };

  export default SimpleListPage;
  