import React, {useState, useEffect} from 'react';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';

import { Box, List, ListItem, ListItemIcon, ListItemText, TextField, Tooltip, IconButton } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { defaultListItemObject } from '../hooks/convertInputDataToXmlFormat';

const CostumList = (props) => {
    const { listObject, listIcon, isEditable, nameOfForm, elementWasRemoved, ...other } = props;
    const [editedListObject, setEditedListObject] = useState(listObject);
    const [keyOffset, setKeyOffset] = useState(1);

    useEffect(() => {
      setKeyOffset(prev => prev + 1);
    }, [editedListObject.children.length]);

    const addListItem = () => {
      let list = cloneDeep(editedListObject);
      list.children.push(cloneDeep(defaultListItemObject));
      listObject.children.push(cloneDeep(defaultListItemObject));
      setEditedListObject(list);
    };

    const removeListItem = (index) => {
      let list = cloneDeep(editedListObject);
      list.children.splice(index, 1);
      listObject.children.splice(index, 1);
      setEditedListObject(list);
    };

    const alterListItemValues = (event, index) => {
      let list = cloneDeep(editedListObject);
      list.children[index].value = event.target.value;
      setEditedListObject(list);
    }

    let attributedIcon = listObject.attributes["listIcon"];
    if(attributedIcon === undefined)
      attributedIcon = listIcon;

    function calculateListIcon(i) {
      if(attributedIcon == "countWithBrace")
      {
        return ("("+(i+1)+")");
      }
      if(attributedIcon == "alphabetic")
        return (String.fromCharCode(('a'.charCodeAt(0) + i)) + ".");
      return attributedIcon;
    }
    
    return(
        <List key={nameOfForm + "_inner"}>
          {isEditable && (
            <Box sx={{display: "flex", width: "100%", justifyContent: "flex-end"}}>
              <Tooltip title="Liste löschen">
                <IconButton aria-label="removeList" onClick={() => {elementWasRemoved()}} color='primary'>
                    <RemoveIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Listen element hinzufügen">
                <IconButton aria-label="addListItem" onClick={addListItem} color='primary'>
                    <AddIcon />
                </IconButton>
              </Tooltip>
            </Box>
          )}
          {editedListObject.children.map(function(childItem, i){
            return(
              <Box>
                {childItem.name == "item" && (
                  <ListItem  key={nameOfForm + "_inner_item"} disablePadding={true}>
                    {attributedIcon != "noIcon" && (<ListItemIcon sx={{justifyContent:"right", marginRight:"10px"}}>{calculateListIcon(i)}</ListItemIcon>)}
                    {!isEditable && (<ListItemText>{childItem.value}</ListItemText>)}
                    { isEditable && (
                      <Box sx={{ display: 'flex', width: '100%'}}
                           key={nameOfForm + "_inner_box_" + i + "_" + keyOffset}>
                        <TextField variant="standard"
                                   key={nameOfForm + "_inner_textfield_" + i + "_" + keyOffset}
                                   id={nameOfForm + "_inner_textfield_" + i}
                                   name={nameOfForm}
                                   sx={{width: '100%'}} 
                                   multiline={childItem.value.length > 70} 
                                   rows={2} 
                                   defaultValue={childItem.value}
                                   onChange={(event) => {alterListItemValues(event, i);}}/>
                          <Tooltip title="Listen element löschen"
                           key={nameOfForm + "_inner_tip_" + i + "_" + keyOffset}>
                          <IconButton aria-label="removeListItem" key={nameOfForm + "_inner_button_" + i + "_" + keyOffset} 
                                      onClick={() => {removeListItem(cloneDeep(i))}} color='primary'>
                              <RemoveIcon key={nameOfForm + "_inner_remove_button_" + i + "_" + keyOffset} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    )}
                  </ListItem>
                )}
                {childItem.name == "list" && (<ListItem><CostumList listObject={childItem}></CostumList></ListItem>)}
              </Box>
            );
          })}
        </List>
    );
}

CostumList.propTypes = {
    listObject: PropTypes.object.isRequired,
    listIcon: PropTypes.any,
    isEditable: PropTypes.bool,
    nameOfForm: PropTypes.string,
    elementWasRemoved: PropTypes.func,
};

export default CostumList;