import React, {useState, forwardRef, useImperativeHandle} from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';

import { Box, IconButton, Tooltip, Input, Popover, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import UploadIcon from '@mui/icons-material/Upload';
import RestoreIcon from '@mui/icons-material/Restore';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import AddLinkIcon from '@mui/icons-material/AddLink';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import PhotoIcon from '@mui/icons-material/Photo';

import {convertInputFormToXmlFormat, defaultParagraphObject, defaultLinkObject, defaultListObject, defaultBackgroundImageObject} from '../hooks/convertInputDataToXmlFormat';
import storeUpdatedXML from '../hooks/storeUpdatedXMLFile';
import { uploadImage, getImageList } from '../hooks/handleResources';

const TopLevelControl = forwardRef((props, ref) => {
    const {editModeOn, enableEditView, reloadContent, pageContent, setPageContent, contentFile, rootTag, justifyContent, width} = props;
    const [loadingSave, setLoadingSave] = useState(false);
    const [loadingUpload, setLoadingUpload] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [addImageButtonColor, setAddImageButtonColor] = useState("primary");
    const [imagePopoverText, setImagePopoverText] = useState("");
    
    var saveClicked = false;
    var uploadClicked = false;
    var imagePopoverPosition = null;

    useImperativeHandle(ref, () => ({
      handleSubmit(event) {
        if(saveClicked)
        {
          handleSave(event);
          saveClicked = false;
        }  
        else if(uploadClicked)
        {
          handleUpload(event);
          uploadClicked = false;
        }
      }
    }));

    const handleSave = async (event) => {
      if(editModeOn)
      {
        setLoadingSave(true);
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        var XMLParser = new (require('react-xml-parser'))();
        var updatedDataString = convertInputFormToXmlFormat(data, pageContent, contentFile);
        var updatedData = XMLParser.parseFromString(updatedDataString);
        setPageContent(updatedData);
        setLoadingSave(false);
        enableEditView();
      }
    };

    const handleUpload = async (event) => {
      if(editModeOn)
      {
        setLoadingUpload(true);
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        var updatedNewsString = convertInputFormToXmlFormat(data, pageContent, contentFile);
        await storeUpdatedXML(contentFile, updatedNewsString);
        setLoadingUpload(false);
        enableEditView();
        await reloadContent();
      }
    };

    const onImageSelect = async(event) => {
      if(event.target.files[0].type.includes("image"))
      {
        const answer = await uploadImage(event.target.files[0]);
        if(answer.hasOwnProperty("success"))
        {
          setAddImageButtonColor("success");
          setAnchorEl(imagePopoverPosition);
          setImagePopoverText("Bild erfolgreich hochgeladen.");
        }
        else
        {
          setAddImageButtonColor("error");
          setAnchorEl(imagePopoverPosition);
          setImagePopoverText("Fehler beim hoch laden, sprich deinen Admin an.");
        }
      }
      else
      {
        setAddImageButtonColor("error");
        setAnchorEl(imagePopoverPosition);
        setImagePopoverText("Bitte wähle ein Bild aus. Erlaubt sind jpg, jpeg, png und gif");
      }
    };

    const handleImagePopoverClose = () => {
      setAddImageButtonColor("primary");
      setAnchorEl(null);
    }; 

    const open = Boolean(anchorEl);
    const idImagePopover = open ? 'image-upload-popover' : undefined;

    return(
        <Box sx={{display:"flex", width: width ? width : "100%", justifyContent:justifyContent ? justifyContent : 'flex-end'}}>
              <IconButton id="editModeButton" aria-label="edit" onClick={enableEditView}>
                {editModeOn ? <EditOffIcon /> : <EditIcon/>}
              </IconButton>
              <LoadingButton
                color="primary"
                loading={loadingSave}
                loadingPosition="start"
                startIcon={editModeOn ? <SaveIcon /> : <RestoreIcon />}
                variant="text"
                type={editModeOn ? "save" : ""}
                onClick={() => {editModeOn ? saveClicked = true : reloadContent();}}
              >
                {editModeOn ? <span>Speichern</span> : <span>Reset</span>}
              </LoadingButton>
              <LoadingButton
                color="primary"
                loading={loadingUpload}
                loadingPosition="start"
                startIcon={<UploadIcon />}
                variant="text"
                disabled={!editModeOn}
                type="upload"
                onClick={() => {uploadClicked = true}}
              >
                <span>Posten</span>
              </LoadingButton>
              <label htmlFor="icon-button-file">
                <Input accept="image/*" id="icon-button-file" name="image" type="file" sx={{display:"none"}} onChange={onImageSelect}/>
                <IconButton color={addImageButtonColor} aria-label="upload picture" component="span" onClick={(event) => {imagePopoverPosition = event.currentTarget}}>
                  <AddPhotoAlternateIcon />
                </IconButton>
                <Popover
                  id={idImagePopover}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleImagePopoverClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <Typography sx={{ p: 2 }}>{imagePopoverText}</Typography>
                </Popover>
              </label>
            </Box>
    );
})

TopLevelControl.propTypes = {
    children: PropTypes.node,
    editModeOn: PropTypes.bool.isRequired,
    enableEditView: PropTypes.func.isRequired,
    reloadContent: PropTypes.func.isRequired,
    pageContent: PropTypes.array.isRequired,
    setPageContent: PropTypes.func.isRequired,
    contentFile: PropTypes.string.isRequired,
    rootTag: PropTypes.string.isRequired,
    justifyContent: PropTypes.string,
    width: PropTypes.string,
};

const ElementLevelControl = (props) => {
  const { children, pageContent, setPageContent, index, parentElement, ...other } = props;
  
  const addElement = async function(elementName) {
    var newElement;
    if(elementName == "paragraph")
      newElement = cloneDeep(defaultParagraphObject);
    else if(elementName == "link")
      newElement = cloneDeep(defaultLinkObject);
    else if(elementName == "list")
      newElement = cloneDeep(defaultListObject);

    var copyPageContent = [...pageContent];
    if(copyPageContent[0].name != "root")
      copyPageContent[index].getElementsByTagName(parentElement)[0].children.push(newElement);
    else
      copyPageContent[0].getElementsByTagName(parentElement)[index].children.push(newElement);
    setPageContent(copyPageContent);
  };

  return(
    <Box sx={{display:"flex", width: "100%", justifyContent:'flex-end'}}> 
      <Tooltip title="Neuer Absatz">
        <IconButton id="addParagraphButton" 
                    aria-label="addParagraph" 
                    onClick={() => {addElement("paragraph");}} 
                    color='primary'>
          <PlaylistAddIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Neuer Link">
        <IconButton id="addLinkButton" 
                    aria-label="addLink" 
                    onClick={() => {addElement("link");}} 
                    color='primary'>
          <AddLinkIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Neue Liste">
        <IconButton id="addListButton" 
                    aria-label="addList" 
                    onClick={() => {addElement("list");}} 
                    color='primary'>
          <FormatListBulletedIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
}

ElementLevelControl.propTypes = {
    children: PropTypes.node,
    pageContent: PropTypes.array.isRequired,
    setPageContent: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    parentElement: PropTypes.string.isRequired,
};

const ImageControl = (props) => {
  const { children, pageContent, setPageContent, index, parentElement, imageElement, tooltipText, ...other } = props;
  
  const [anchorEl, setAnchorEl] = useState(null);
  const [imageList, setImageList] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");

  const changeBackground = async(event) => {
    setAnchorEl(event.currentTarget);
    await setImageList(await getImageList());
    setSelectedImage("");
  };
  
  const handleImageSelect = (event) => {
    setSelectedImage(event.target.value);
    var copyPageContent = [...pageContent];

    var existingImage = undefined;
    if(copyPageContent[0].name != "root")
    {
      existingImage = copyPageContent[index].getElementsByTagName(parentElement)[0].getElementsByTagName(imageElement)[0];
    }
    else
    {
      existingImage = copyPageContent[0].getElementsByTagName(parentElement)[index].getElementsByTagName(imageElement)[0];
    }
    if(existingImage)
    {
      existingImage.value = event.target.value;
    }
    else
    {
      var newElement = cloneDeep(defaultBackgroundImageObject);
      newElement.value = event.target.value;
  
      if(copyPageContent[0].name != "root")
        copyPageContent[index].getElementsByTagName(parentElement)[0].children.push(newElement);
      else
        copyPageContent[0].getElementsByTagName(parentElement)[index].children.push(newElement);  
    }
    setPageContent(copyPageContent);
  };

  const open = Boolean(anchorEl);
  
  return(
    <Box>
      <Tooltip title={tooltipText ? tooltipText : "Hintergrundbild ändern"}>
        <IconButton id="changeBackgroundButton" 
                    aria-label="changeBackground" 
                    onClick={changeBackground} 
                    color='primary'>
          <PhotoIcon />
        </IconButton>
      </Tooltip>
        <Popover
          id={open ? "image-select-popover" : ""}
          open={open}
          anchorEl={anchorEl}
          onClose={() => {setAnchorEl(null);}}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <InputLabel id="image-select-label">Bild Name</InputLabel>
          <Select
            labelId="image-select-label"
            id="image-select"
            value={selectedImage}
            label="Bild Name"
            onChange={handleImageSelect}
          >
            <MenuItem key={"image-select-option" + 0} id={"image-select-option" + 0} value={""}>Keins</MenuItem>
            {imageList.map((element, i) => <MenuItem key={"image-select-option" + i + 1} id={"image-select-option" + i + 1} value={element}>{element}</MenuItem>)}
          </Select>
        </Popover>
      </Box>
  );
}

ImageControl.propTypes = {
    children: PropTypes.node,
    pageContent: PropTypes.array.isRequired,
    setPageContent: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    parentElement: PropTypes.string.isRequired,
    imageElement: PropTypes.string.isRequired,
    tooltipText: PropTypes.string,
};

export {TopLevelControl, ElementLevelControl, ImageControl};