async function fetchDataFromServer(filename) {
    var XMLParser = require('react-xml-parser');

    var response = (await (await fetch(filename)).text()).valueOf();
    return new XMLParser().parseFromString(response);
};

async function fetchDataFromLocalhost(filename) {
    var XMLParser = require('react-xml-parser');
    var request = {filename: filename}
    var response = await 
        fetch('http://localhost:8080/getFile', {
            method: 'POST',
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(request)
        }).then(function(response){
            return response.text();   
           });
    return new XMLParser().parseFromString(response);
};

async function fetchData(filename) {
    if(window.location.hostname == "localhost")
        return fetchDataFromLocalhost(filename);
    return fetchDataFromServer(filename);
};
async function fetchMultipleFilesFromServer(filenames) {
    var XMLParser = require('react-xml-parser');

    var result = []
    await Promise.all(filenames.map(async (file) => {
        if(file !== undefined)
        {
            const response = (await (await fetch(file)).text()).valueOf();
            var xml = new XMLParser().parseFromString(response);
            result.push(xml);
        }
        else
        {
            result.push(undefined);
        }
    }));
    return result;
};

async function fetchMultipleFilesFromLocalhost(filenames) {
    var XMLParser = require('react-xml-parser');
    var result = []
    await Promise.all(filenames.map(async (file) => {
        if(file !== undefined)
        {
            var request = {filename: file}
            const response = await fetch('http://localhost:8080/getFile', { method: 'POST',
                                                                            headers: {
                                                                            "Content-Type": "application/json"
                                                                            },
                                                                            body: JSON.stringify(request)
                                                                        }).then(function(response){
                                                                                return response.text();   
                                                                            });
            result.push(new XMLParser().parseFromString(response));
        }
        else
        {
            result.push(undefined);
        }
      }));

    return result;
};
async function fetchMultipleFiles(filenames) {
    if(window.location.hostname == "localhost")
        return fetchMultipleFilesFromLocalhost(filenames);
    return fetchMultipleFilesFromServer(filenames);
}

async function uploadImageToTarget(imageFile, url) {
    const formData = new FormData();
    formData.append('image', imageFile);
    formData.append('token', window.sessionStorage.getItem("token"));
    formData.append('username', window.sessionStorage.getItem("username"));
    return fetch(url, {
        method: 'POST',
        body: formData
    })
    .then(function(response){
     return response.json();   
    })
    .catch(function(reason){
        console.log(reason);
    });
};

async function uploadImage(imageFile) {
    if(window.location.hostname == "localhost")
        return uploadImageToTarget(imageFile, 'http://localhost:8080/uploadImage');
    return uploadImageToTarget(imageFile, 'https://kita-kreiselternrat-stade.de/handleImageUpload.php');
};

async function getImageListFromTarget(url) {
    return fetch(url, {
        method: 'POST'
    })
    .then(function(response){
     return response.json();   
    })
    .catch(function(reason){
        console.log(reason);
    });
};

async function getImageList() {
    if(window.location.hostname == "localhost")
        return getImageListFromTarget('http://localhost:8080/getImageList');
    return getImageListFromTarget('https://kita-kreiselternrat-stade.de/getImageList.php');
};

async function fetchJSONFromServer(filename) {
    return (await (await fetch(filename)).json()).valueOf();
};

async function fetchJSONFromLocalhost(filename) {
    var request = {filename: filename}
    var response = await 
        fetch('http://localhost:8080/getFile', {
            method: 'POST',
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(request)
        }).then(function(response){
            return response.json();   
           });
    return response;
};

async function fetchJSON(filename) {
    if(window.location.hostname == "localhost")
        return fetchJSONFromLocalhost(filename);
    return fetchJSONFromServer(filename);
};

export {fetchData, fetchMultipleFiles, fetchJSON, uploadImage, getImageList};